/* Navbar placeholder to prevent layout shift */
.navbar-placeholder {
  height: 53px; /* Mobile height */
}

@media (min-width: 768px) {
  .navbar-placeholder {
    height: 62px; /* Desktop height */
  }

  .sub-nav-placeholder {
    height: 45px; /* Desktop height */
  }
}

/* Hide placeholder once custom element is defined */
jt-navbar:has(.navbar) + .navbar-placeholder {
  display: none;
}

jt-navbar:has(jt-sub-nav) + .navbar-placeholder + .sub-nav-placeholder {
  display: none;
}

/* Position the actual navbar absolutely to overlay the placeholder */
jt-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

/* Add padding to the body to account for fixed navbar */
body {
  padding-top: 53px; /* Mobile height */
}

@media (min-width: 768px) {
  body {
    /* Handles sub nav wrapping */
    padding-top: 127px; /* Desktop height */
  }
}

@media (min-width: 992px) {
  body {
    padding-top: 107px; /* Desktop height */
  }
}
